import React from 'react';
import { Image } from 'react-bootstrap'
import WorkExperience from './WorkExperience';
import homepetsCover from '../images/homepetsCover.jpeg'
import UXProcess from '../images/UXprocess.png'
import personas from '../images/personas.png'
import sitemap from '../images/sitemap.png'
import sketches from '../images/sketches.png'
import wireframes from '../images/wireframes.png'
import mockups_1 from '../images/mockups-1.jpg'
import mockups_2 from '../images/mockups-2.jpg'
import mockups_3 from '../images/mockups-3.jpg'

const UXHomePets = () => {
  return (
    <div style={{ margin: '0' }}>
      <div
        style={{
          alignContent:'center',
          display: 'flex',
          textAlign:'center',
          height: '200px',
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '30px',
          paddingBottom: '20px',
          margin: '0',
          backgroundImage: `url(${homepetsCover})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{ margin: 'auto', fontWeight: 'bold' }} 
         className='title'>HomePets</h1>
      </div>

      <h5 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '40px' }}>Welcome to HomePets</h5>
      <p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px'}}
        >
        Home Pets is a website which allows adopting lovely pets. Many people are fond of pets and 
				could not find the right place to adopt a pet, this platform allows pet lovers to get in 
				contact with shelters to adopt and also allow them to participate in pet events held by various shelters out there.
				</p>
  
      <hr className='horizontal-line-css' />
      
			<div className='row' style={{ paddingLeft:"20px", paddingRight: "20px", margin:'0' }}>
				<div className='col-md-6 mr-auto' >
				<h5 className='title' style={{ textAlign: 'left', marginTop:'20px' }}>So, what's the problem?</h5>
				<p 
					className='subTitle' 
					style={{ textAlign: 'left'}}
					>
					Currently, there is no such platform who provides all information of pets in nearby shelters and
					allows users to hire a pet companion</p>
		
				</div>
				<div className='col-md-6 mr-auto' >
				<h5 className='title' style={{ textAlign: 'left', marginTop:'20px' }}>Target user</h5>
				<p 
					className='subTitle' 
					style={{ textAlign: 'left'}}
					>
					Pet lovers who are fond of pets want to adopt them. People with all genders and mostly 18-40
					years age group</p>
		
				</div>
			</div>
			
			<hr className='horizontal-line-css' />

			<h5 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '20px' }}>My Role</h5>
      <p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px'}}
        >
        My role in this project as a UX designer was to take ownership of the app’s
				design, from concept to delivery. My responsibility included UX research, wireframing,
				prototyping, usability study and creation of high fidelity prototype
				</p>
			
			<h5 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '40px' }}>UX Process</h5>
			
			<Image src={UXProcess} style={{ width:"80%", alignSelf: "center", marginTop: '20px', marginBottom: '20px' }}/>
			
			<hr className='horizontal-line-css' />

			<h3 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '20px' }}>Research</h3>
      <p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px'}}
        >
        We conducted user research and received feedback from users that we incorporated into user
				personas. For example, our user persona, Charlotte is a 25 year old girl residing in the city of
				Sydney, she is a fashion designer. She has a home office and mostly works at home. As a child,
				she always wanted to adopt a puppy but because of parents she was not allowed to. Now as a
				grownup and independent woman she has decided to have a pet.
				</p>

			<Image src={personas} 
				style={{ 
					maxWidth:"60%", 
					alignSelf: "center", 
					marginTop: '20px', 
					marginBottom: '20px' 
				}}/>

			<h5 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '40px' }}>Insights learned</h5>
			<p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px', marginBottom: '0'}}
        >
        From user research, we learned that there were some pain points for users. The two biggest
				pain points users had are
				</p>
				<ul
					className='subTitle' 
					style={{ textAlign: 'left', marginLeft: '20px', marginTop:'0', marginBottom: '0'}}
				>
					<li>
					Lack of knowledge of how to raise a pet
					</li>
					<li>
					No knowledge of any dog/cat breed
					</li>
				</ul>
				<p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px', marginTop:'0'}}
        >
				These we are trying to solve through this UX design.
				</p>

				<h5 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '40px' }}>Information Architechture</h5>
				<p
				className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px', marginBottom: '0'}}
				>
				A sitemap was created combining the task flows with user navigation paths for the three major task flows,
				</p>
				<ul
					className='subTitle' 
					style={{ textAlign: 'left', marginLeft: '20px', marginTop:'0'}}
				>
					<li>
					Scheduling a visit to the shelter.
					</li>
					<li>
					Enquiring about interested pet animals.
					</li>
				</ul>

				<Image src={sitemap} 
				style={{ 
					width:"70%", 
					alignSelf: "center", 
					marginTop: '20px', 
					marginBottom: '20px' 
				}}/>
			
			<hr className='horizontal-line-css' />

			 <h3 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '20px' }}>UI Ideation : sketches and wireframes</h3>
      <p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px'}}
        >
        Here’s how we put our users’ needs first. The process below begins with our initial ideas
				wireframes, then moves to mockups, and finally to the high-fidelity prototype.
				</p>
			
				<Image src={sketches} 
				style={{ 
					width:"100%", 
					alignSelf: "center", 
					marginTop: '20px', 
					marginBottom: '20px' 
				}}/>
			
			<h5 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '20px' }}>Wireframes</h5>
      <p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px'}}
        >
        In this simple version, you can see how the designer approaches solving the user’s needs.
				</p>
			
				<Image src={wireframes} 
				style={{ 
					maxWidth:"70%", 
					alignSelf: "center", 
					marginTop: '20px', 
					marginBottom: '20px' 
				}}/>

			<h5 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '20px' }}>Mockups</h5>
      <p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px'}}
        >
        Now the design really begins to take shape: actual text is used, colors are applied, and images
				are added. This mockup shows a visual that gives a better idea of the final design.
				</p>

			<div className='row' style={{ margin:'0' }}>
				<div className='col-sm'>
					<Image src={mockups_1} 
						style={{ 
							maxWidth:"80%", 
							alignSelf: "center", 
							marginTop: '20px', 
							boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
					}} rounded/>
				</div>
				<div className='col-sm'>
					<Image src={mockups_2} 
						style={{ 
							maxWidth:"80%", 
							alignSelf: "center", 
							marginTop: '20px', 
					}} rounded/>
					<Image src={mockups_3} 
						style={{ 
							maxWidth:"80%", 
							alignSelf: "left", 
							marginTop: '20px', 
					}} rounded/>
				</div>
			</div>
						
	{/* <h3 className='title' style={{ textAlign: 'left', marginLeft: '20px', marginTop: '50px' }}>Closing Thoughts</h3>
      <p 
        className='subTitle' 
        style={{ textAlign: 'left', marginLeft: '20px'}}
        >
        This was my small attempt towards imagining how we can can create a portal to adopt a pet along with shelter details. 
				</p> */}

			<hr className='horizontal-line-css' style={{ marginTop: '40px' }}/>

    </div>
  );
};

export default UXHomePets;
