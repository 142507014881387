import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Skills from './Skills';
import Projects from './Projects';
import WorkExperience from './WorkExperience';
import UXPortfolio from './UXPortfolio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import myPic from '../images/my-pic2.jpg';

const TitleContainer = () => {
  return (
    <div>
      <Row
        style={{
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '30px',
          margin: '0',
        }}
      >
        <Col style={{ color: '#fff', textAlign: 'left', padding: '20px' }}>
          <h1 className='title'>ADITYA MARATHE</h1>
          <h4 className='subTitle'>Software Engineer</h4>
          <p className='subTitle'>
            I'm a UX designer and developer . I write about software development on. Want to know how I may help your project? Check out my
            project portfolio and online resume.
          </p>
          <p>
            <Link to='/Portfolio' className='btn mainButton'>
              <FontAwesomeIcon icon={faAngleDoubleRight} /> View Portfolio
            </Link>
            <Link
              Link
              to='/Resume'
              className='btn subButton'
              href='#'
              style={{ margin: '5px' }}
            >
              <FontAwesomeIcon icon={faFileAlt} /> View Resume
            </Link>
          </p>
        </Col>
        <Col
          style={{ color: '#fff', textAlign: 'center', padding: '30px' }}
          hidden
        >
          <Image
            src={myPic}
            style={{ maxWidth: '300px', alignSelf: 'center' }}
            hidden
          />
        </Col>
      </Row>
      <hr className='horizontal-line-css' />
      <Skills />
      <hr className='horizontal-line-css' />
      <UXPortfolio/>
      <hr className='horizontal-line-css' />
      <WorkExperience />
      {/* <hr className='horizontal-line-css' /> */}
      {/* <Projects /> */}
    </div>
  );
};
export default TitleContainer;
