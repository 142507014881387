import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons";
import {
  faFileAlt,
  faPhone,
  faMailBulk,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";

const ResumeMain = () => {
  return (
    <div style={{ margin: "0" }}>
      <div
        style={{
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingTop: "30px",
          paddingBottom: "20px",
          margin: "0",
        }}
      >
        <h1 className="title">Resume</h1>
        <p className="subTitle">
          Welcome to my online Resume. You can Download PDF version here
        </p>
        <a
          href="https://drive.google.com/file/d/1a86zJwnFzaZD9laMVKf2PGTgDI5US1mA/view?usp=sharing"
          className="btn mainButton"
          style={{
            width: "150px",
            alignItems: "center",
            margin: "0 auto",
            marginTop: "20px",
            display: "block",
          }}
        >
          <FontAwesomeIcon icon={faFileAlt} /> Download PDF
        </a>
      </div>
      <hr className="horizontal-line-css" />
      <div className="resume-card">
        <div className="row">
          <div className="col-md" style={{ textAlign: "left" }}>
            <h1 className="title">ADITYA MARATHE</h1>
            <h5 className="subTitle">Software Engineer</h5>
          </div>
          <div id="resume-left" className="col-md">
            <p style={{ margin: "0" }} className="subTitle">
              <FontAwesomeIcon icon={faPhone} /> +91 80-07-880-284
            </p>
            <p className="subTitle" style={{ margin: "0" }}>
              <FontAwesomeIcon icon={faMailBulk} /> aditya33.am@gmail.com
            </p>
            <p className="subTitle" style={{ margin: "0" }}>
              <FontAwesomeIcon icon={faLocationArrow} /> Nashik, India
            </p>
          </div>
        </div>
        <hr className="horizontal-line-css" />
        <div style={{ textAlign: "left" }}>
          <div className="row">
            <div className="col-md-8">
              <h4 className="title">
                <span className="vl" style={{ marginRight: "15px" }}></span>
                My Work
              </h4>
              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                Member of Technical Staff | Coriolis Technologies Pvt. Ltd.
              </h6>
              {/* <p className="subTitle small" style={{ margin: "0" }}>
                <ul>
                  <li>
                    Developed APIs for smart parking system using
                    Express.js/Node.js/MongoDB.
                  </li>
                  <li>
                    Developed Admin Panel of Mobbypark Smart parking using
                    React.js.
                  </li>
                  <li>Developed Android App using REST APIs.</li>
                </ul>
              </p> */}
              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                Software Engineering Intern | Mobbypark Pte Ltd.
              </h6>
              <p className="subTitle small" style={{ margin: "0" }}>
                <ul>
                  <li>
                    Developed APIs for smart parking system using
                    Express.js/Node.js/MongoDB.
                  </li>
                  <li>
                    Developed Admin Panel of Mobbypark Smart parking using
                    React.js.
                  </li>
                  <li>Developed Android App using REST APIs.</li>
                </ul>
              </p>

              <h4 className="title" style={{ marginTop: "30px" }}>
                <span className="vl" style={{ marginRight: "15px" }}></span>
                Projects
              </h4>

              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                Android Development | HEX OCT BIN DEC Convertor
              </h6>
              <p className="subTitle small" style={{ margin: "0" }}>
                Worked on an Android app, which converts number system and
                launched on play store.
              </p>

              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                Android Development | Zone XIII – JCI India
              </h6>
              <p className="subTitle small" style={{ margin: "0" }}>
                Worked on an Android app, which is about organization named
                Junior Chamber International India and launched on play store.
              </p>

              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                Web Development | MVP Srujan
              </h6>
              <p className="subTitle small" style={{ margin: "0" }}>
                Website for the most awaited event of MVP Samaj Srujan which is
                hosted by department of Information Technology of KBTCOE, Nashik
              </p>

              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                Android Development | JCI eBusiness Directory
              </h6>
              <p className="subTitle small" style={{ margin: "0" }}>
                Worked on an Android app, which is about organization named
                Junior Chamber International India and launched on play store.
              </p>

              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                Android Development | PLGPL Alumni
              </h6>
              <p
                className="subTitle small"
                style={{ margin: "0", marginBottom: "30px" }}
              >
                Official Directory for Puranmal Lahoti Government Polytechnic,
                Latur Alumni consisting of all information about them.
              </p>
            </div>
            <div className="col-md-auto">
              <h4 className="title">
                <span className="vl" style={{ marginRight: "15px" }}></span>
                Education
              </h4>
              <h6 className="title" style={{ margin: "0", marginTop: "30px" }}>
                BE in Information Technology
              </h6>
              <p
                className="subTitle small"
                style={{ margin: "0", marginBottom: "30px" }}
              >
                NDMVP's KBTCOE, Nashik. 2016-2020
              </p>

              <h4 className="title">
                <span className="vl" style={{ marginRight: "15px" }}></span>
                Technical Skills
              </h4>
              <p className="subTitle small" style={{ margin: "0" }}>
                Python/Unix/Linux
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                Android Development/ User Experience
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                Web Development/ M.E.R.N Stack
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                React.Js/React Native/Angular.Js
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                C/C++/Java/Javascript/HTML5/CSS3/Python
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                SQL/Mysql/MongoDB
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                Adobe Photoshop/Illustrator/XD/Figma
              </p>
              <p
                className="subTitle small"
                style={{ margin: "0", marginBottom: "30px" }}
              >
                Flutter(Beginner)
              </p>

              <h4 className="title">
                <span className="vl" style={{ marginRight: "15px" }}></span>
                Languages
              </h4>
              <p className="subTitle small" style={{ margin: "0" }}>
                English
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                Hindi
              </p>
              <p className="subTitle small" style={{ margin: "0" }}>
                Marathi
              </p>
            </div>
          </div>
        </div>
        <hr className="horizontal-line-css" />
        <p>
          <a
            className="subTitle not-active-link social-link-btn"
            style={{ textDecoration: "none" }}
            href="https://github.com/amazingAdii/"
          >
            <FontAwesomeIcon icon={faGithub} /> Github
          </a>
          <a
            className="subTitle not-active-link social-link-btn"
            style={{ textDecoration: "none" }}
            href="https:/www.linkedin.com/in/aditya-marathe-394283aa"
          >
            <FontAwesomeIcon icon={faLinkedin} /> Linkedin
          </a>
          <a
            className="subTitle not-active-link social-link-btn"
            style={{ textDecoration: "none" }}
            href="https://stackoverflow.com/users/7593511/amazingadii?tab=profile"
          >
            <FontAwesomeIcon icon={faStackOverflow} />
            StackOverflow
          </a>
        </p>
      </div>
    </div>
  );
};

export default ResumeMain;
