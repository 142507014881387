import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGithub, faLinkedin, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { faUser, faLaptopCode, faFileAlt, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import sideImg from '../images/my-pic.JPG';
import ModalShow from './ModalShow'

const MenuAM = () => {

  const [modalShow, setModalShow] = React.useState(false);

  return(
    <Navbar
    className="d-block d-md-none"
    collapseOnSelect 
    expand="lg" 
    variant="dark"
    style={{ backgroundColor: "#1e2a3a", color: "#ffffff" }}>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ marginRight:'10px' }}/>
  <Navbar.Brand href="#home">Aditya Marathe</Navbar.Brand>

  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
    </Nav>
    <Nav>
      <Image src={sideImg} style={{ maxWidth:"200px", alignSelf: "center" }} roundedCircle/>
      <p style={{ marginTop:'10px' }} >Hi, My name is Aditya Marathe and I'm a UX designer, developer. Welcome to my personal Website.</p>
      <p>
        <a className="btn btn-circle mainButton" href="https://github.com/amazingAdii/"><FontAwesomeIcon icon={faGithub} /></a>
        <a> </a>
        <a className="btn btn-circle mainButton" href="https:/www.linkedin.com/in/aditya-marathe-394283aa"><FontAwesomeIcon icon={faLinkedin} /></a>
        <a> </a>
        <a className="btn btn-circle mainButton" href="https://stackoverflow.com/users/7593511/amazingadii?tab=profile"><FontAwesomeIcon icon={faStackOverflow} /></a>
      </p>
      <Link className="nav-link" to="/"><FontAwesomeIcon icon={faUser} /> About Me</Link>
      <Link className="nav-link" to="/Portfolio"><FontAwesomeIcon icon={faLaptopCode} /> Portfolio</Link>
      <Link className="nav-link" to="/Resume"><FontAwesomeIcon icon={faFileAlt} /> Resume</Link>
      <Link className="nav-link" onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faAddressBook} /> Contact</Link>

      <ModalShow
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </Nav>
  </Navbar.Collapse>
</Navbar>
  );
}

export default MenuAM;