import React from "react";
import MenuAM from "../Components/MenuAM";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import UXHomePets from '../Components/UXHomePets'

const UXwork = () => {
  return (
    <div className="wrapper">
      <Sidebar type="cmp2" />
      <div id="content">
        <MenuAM />
        <UXHomePets style={{ padding: "10px" }} />
        <Footer />
      </div>
    </div>
  );
};

export default UXwork;
