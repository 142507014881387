import React from 'react';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faLinkedin,
  faStackOverflow,
} from '@fortawesome/free-brands-svg-icons';
import {
  faUser,
  faLaptopCode,
  faFileAlt,
  faAddressBook,
} from '@fortawesome/free-solid-svg-icons';
import sideImg from '../images/my-pic.JPG';
import ModalShow from '../Components/ModalShow';

const Sidebar = (props) => {
  let cmp;
  if (props.type === 'cmp1') {
    cmp = <Cmp1 />;
  } else if (props.type === 'cmp2') {
    cmp = <Cmp2 />;
  } else if (props.type === 'cmp3') {
    cmp = <Cmp3 />;
  }

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <nav id='sidebar' style={{ padding: '20px' }}>
      <h4 className='title' style={{ marginTop: '15px', marginBottom: '30px' }}>
        Aditya Marathe
      </h4>
      <Image
        src={sideImg}
        style={{ maxWidth: '150px', alignSelf: 'center' }}
        roundedCircle
      />
      <p className='subTitle' style={{ marginTop: '10px' }}>
        Hi, My name is Aditya Marathe and I'm a UX designer, developer. Welcome to my
        personal Website.
      </p>
      <p>
        <a
          className='btn btn-circle mainButton'
          href='https://github.com/amazingAdii/'
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a> </a>
        <a
          className='btn btn-circle mainButton'
          href='https:/www.linkedin.com/in/aditya-marathe-394283aa'
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a> </a>
        <a
          className='btn btn-circle mainButton'
          href='https://stackoverflow.com/users/7593511/amazingadii?tab=profile'
        >
          <FontAwesomeIcon icon={faStackOverflow} />
        </a>
      </p>
      <hr className='horizontal-line-css' />
      {cmp}
      <hr className='horizontal-line-css' />
      <Button
        className='btn mainButton'
        onClick={() => setModalShow(true)}
        style={{ width: '80%', border: '0' }}
      >
        <FontAwesomeIcon icon={faAddressBook} /> Contact
      </Button>

      <ModalShow show={modalShow} onHide={() => setModalShow(false)} />
    </nav>
  );
};

const Cmp1 = () => {
  return (
    <ul className='navbar-nav'>
      <li className='nav-item'>
        <Link to='/' className='nav-link active-link'>
          <FontAwesomeIcon icon={faUser} /> About
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/Portfolio' className='nav-link not-active-link'>
          <FontAwesomeIcon icon={faLaptopCode} /> Portfolio
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/Resume' className='nav-link not-active-link'>
          <FontAwesomeIcon icon={faFileAlt} /> Resume
        </Link>
      </li>
    </ul>
  );
};

const Cmp2 = () => {
  return (
    <ul className='navbar-nav'>
      <li className='nav-item'>
        <Link to='/' className='nav-link not-active-link'>
          <FontAwesomeIcon icon={faUser} /> About
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/Portfolio' className='nav-link active-link'>
          <FontAwesomeIcon icon={faLaptopCode} /> Portfolio
        </Link>
      </li>
      <li className='nav-item'>
        <Link Link to='/Resume' className='nav-link not-active-link'>
          <FontAwesomeIcon icon={faFileAlt} /> Resume
        </Link>
      </li>
    </ul>
  );
};

const Cmp3 = () => {
  return (
    <ul className='navbar-nav'>
      <li className='nav-item'>
        <Link to='/' className='nav-link not-active-link'>
          <FontAwesomeIcon icon={faUser} /> About
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/Portfolio' className='nav-link not-active-link'>
          <FontAwesomeIcon icon={faLaptopCode} /> Portfolio
        </Link>
      </li>
      <li className='nav-item'>
        <Link Link to='/Resume' className='nav-link active-link'>
          <FontAwesomeIcon icon={faFileAlt} /> Resume
        </Link>
      </li>
    </ul>
  );
};

export default Sidebar;
