import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faChrome } from '@fortawesome/free-brands-svg-icons';

import WorkExperience from '../Components/WorkExperience';
import UXPortfolio from '../Components/UXPortfolio'

const PortfolioMain = () => {
  return (
    <div style={{ margin: '0' }}>
      <div
        style={{
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '30px',
          paddingBottom: '20px',
          margin: '0',
        }}
      >
        <h1 className='title'>Portfolio</h1>
        <p className='subTitle'>
          Welcome to my online portfolio. These are the projects I have done so
          far, I hope you will enjoy it.
        </p>
      </div>
      <hr className='horizontal-line-css' />
      <WorkExperience />
      <UXPortfolio/>
      <h2 className='title' style={{ textAlign: 'left', marginLeft: '30px' }}>
        <span className='vl' style={{ marginRight: '15px' }}></span>My Projects
      </h2>
      <div
        className='row'
        style={{
          textAlign: 'left',
          marginTop: '30px',
          margin: '0',
          marginLeft: '15px',
        }}
      >
        <div className='col-sm'>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://play.google.com/store/apps/details?id=com.maddy.adiii.plgplalumni&hl=en'
          >
            <h5>Android App | PLGPL Alumni</h5>
            <p className='small'>
              Official Directory for Puranmal Lahoti Government Polytechnic,
              Latur Alumni consisting of all information about them.
            </p>
            <div className='go-corner'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p>
          </a>
        </div>
        <div className='col-sm'>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://play.google.com/store/apps/details?id=com.maddy.adiii.JCIBusinessDirectory&hl=en'
          >
            <h5>Android App | JCI eBusiness Directory</h5>
            <p className='small'>
              Worked on an Android app, which is about organization named Junior
              Chamber International India and launched on play store.
            </p>
            <div className='go-corner' href='#'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p>
          </a>
        </div>
      </div>
      <div
        className='row'
        style={{ textAlign: 'left', margin: '0', marginLeft: '15px' }}
      >
        <div className='col-sm'>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://itweb-fe52b.firebaseapp.com/'
          >
            <h5>Website | MVP Srujan</h5>
            <p className='small'>
              Website for the most awaited event of MVP Samaj Srujan which is
              hosted by department of Information Technology of KBTCOE, Nashik
            </p>
            <div className='go-corner' href='#'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faChrome} /> Hosted on Firebase
            </p>
          </a>
        </div>
        <div className='col-sm'>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://play.google.com/store/apps/details?id=com.maddy.adiii.zonexiii_jciindia&hl=en'
          >
            <h5>Android App | Zone XIII – JCI India</h5>
            <p className='small'>
              Worked on an Android app, which is about organization named Junior
              Chamber International India and launched on play store.
            </p>
            <div className='go-corner' href='#'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p>
          </a>
        </div>
      </div>
      <div
        className='row'
        style={{ textAlign: 'left', margin: '0', marginLeft: '15px' }}
      >
        <div className='col-sm'>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://play.google.com/store/apps/details?id=com.maddy.aaditya.bhdconverter&hl=en'
          >
            <h5>Android App | HEX OCT BIN DEC Convertor</h5>
            <p className='small'>
              Worked on an Android app, which converts number system which comes
              handy in Digital Electronics and launched on play store.
            </p>
            <div className='go-corner'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p>
          </a>
        </div>
        <div className='col-sm' style={{ visibility: 'hidden' }}>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://play.google.com/store/apps/details?id=com.maddy.adiii.JCIBusinessDirectory&hl=en'
          >
            <h5>Android App | JCI eBusiness Directory</h5>
            <p className='small'>
              Worked on an Android app, which is about organization named Junior
              Chamber International India and launched on play store.
            </p>
            <div className='go-corner' href='#'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PortfolioMain;
