import React from "react";
import MenuAM from "../Components/MenuAM";
import TitleContainer from "../Components/TitleContainer";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";

const Home = () => {
  return (
    <div className="wrapper">
      <Sidebar type="cmp1" />
      <div id="content">
        <MenuAM />
        <TitleContainer style={{ padding: "10px" }} />
        <hr className="horizontal-line-css" />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
