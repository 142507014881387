import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import Home from './Pages/Home'
import Portfolio from './Pages/Portfolio'
import Resume from './Pages/Resume'
import UXwork from './Pages/UXwork'

function App() {
  return (
    <Router>
    <div className="App" style={{ backgroundColor:"#111821"}}>
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/Portfolio" component={Portfolio}></Route>
      <Route exact path="/Resume" component={Resume}></Route>
      <Route exact path="/homepets" component={UXwork}></Route> 
    </div>
    </Router>
  );
}

export default App;
