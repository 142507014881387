import React from "react";
import Sidebar from "../Components/Sidebar";
import MenuAM from "../Components/MenuAM";
import ResumeMain from "../Components/ResumeMain";
import Footer from "../Components/Footer";

const Resume = () => {
  return (
    <div className="wrapper">
      <Sidebar type="cmp3" />
      <div id="content">
        <MenuAM />
        <ResumeMain style={{ padding: "10px" }} />
        <hr className="horizontal-line-css" />
        <Footer />
      </div>
    </div>
  );
};

export default Resume;
