import React from "react";
import { Link } from 'react-router-dom';

const UXPortfolio = () => {
  return (
    <div
      style={{
        textAlign: "left",
        marginTop: "30px",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingBottom: "30px",
      }}
    >
      <h2 className="title">
        <span className="vl" style={{ marginRight: "15px" }}></span>UX Work
      </h2>
      <div className="row">
        <div className="col-md-6 mr-auto">
          <Link
            className="card_ux"
            style={{ textDecoration: "none", background: "../images/homepetsCover.jpeg"}}
            to='/homepets'
          >
            <h3>HomePets</h3>
            {/* <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p> */}
          </Link>
        </div>
        <div className="col-md-6 mr-auto">
          <a
            className="card_fl"
            style={{ textDecoration: "none", background: "../images/findlocalcover.png"}}
            href='https://www.behance.net/gallery/120511753/FindLocal-Case-study?'
          >
            <h3>FindLocal</h3>
            {/* <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p> */}
          </a>
        </div>
        {/* <div className='col-md-4'>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://play.google.com/store/apps/details?id=com.maddy.adiii.JCIBusinessDirectory&hl=en'
          >
            <h5>JCI eBusiness Directory</h5>
            <p className='small'>
              Worked on an Android app, which is about organization named Junior
              Chamber International India and launched on play store.
            </p>
            <div className='go-corner' href='#'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faGooglePlay} /> Available on Playstore
            </p>
          </a>
        </div>
        <div className='col-md-4 mr-auto'>
          <a
            className='card1'
            style={{ textDecoration: 'none' }}
            href='https://itweb-fe52b.firebaseapp.com/'
          >
            <h5>MVP Srujan</h5>
            <p className='small'>
              Website for the most awaited event of MVP Samaj Srujan which is
              hosted by department of Information Technology of KBTCOE, Nashik
            </p>
            <div className='go-corner' href='#'>
              <div className='go-arrow'>→</div>
            </div>
            <p className='small'>
              <FontAwesomeIcon icon={faChrome} /> Hosted on Firebase
            </p>
          </a>
        </div> */}
        <div className="col-md-6 mr-auto">
          {/* <a
            className="card1"
            style={{ textDecoration: "none" }}
            href="https://www.mobbypark.com"
          >
            <h5>Software Engineer Intern</h5>
            <p className="medium">
              at{" "}
              <span style={{ fontWeight: "bold" }}>Mobbypark Pte. Ltd. </span>|{" "}
              <span className="small"> March-April 2020</span>{" "}
            </p>
            <p className="small">
              Mobbypark is an organization which provides cashless, ticketless,
              stress-free parking. You can search and book for parking online.
            </p>
            <div className="go-corner">
              <div className="go-arrow">→</div>
            </div>
          </a> */}
        </div>
      </div>
      {/* <Link
        to='/Portfolio'
        className='btn mainButton'
        style={{
          width: '150px',
          alignItems: 'center',
          margin: '0 auto',
          marginTop: '20px',
          display: 'block',
        }}
      >
        <FontAwesomeIcon icon={faAngleDoubleRight} />
        View More
      </Link> */}
    </div>
  );
};

export default UXPortfolio;
