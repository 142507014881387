import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMailBulk } from '@fortawesome/free-solid-svg-icons';

const ModalShow = (props) => {
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <div style={{ backgroundColor: '#1e2a3a', padding: '20px' }}>
        <h3 className='title'>Contact Me</h3>
        <p style={{ margin: '0', marginTop: '20px' }} className='subTitle'>
          <FontAwesomeIcon icon={faPhone} /> Phone No: +91 80-07-880-284
        </p>
        <p className='subTitle' style={{ margin: '0' }}>
          <FontAwesomeIcon icon={faMailBulk} /> Email: aditya33.am@gmail.com
        </p>
      </div>
    </Modal>
  );
};

export default ModalShow;
