import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <p
      className="subTitle"
      style={{ margin: "0", marginTop: "30px", paddingBottom: "20px" }}
    >
      Made with <FontAwesomeIcon icon={faHeart} color="#a83f39" /> by Aditya
      Marathe
    </p>
  );
};

export default Footer;
