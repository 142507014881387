import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faJava,
  faJsSquare,
  faCss3Alt,
  faHtml5,
  faAndroid,
  faAngular,
  faReact,
  faGit,
  faAdobe,
  faPython,
  faLinux,
  faNodeJs,
  faWordpress,
  faFigma
} from "@fortawesome/free-brands-svg-icons";
import ReactTooltip from "react-tooltip";

const Skills = () => {
  return (
    <div
      style={{
        textAlign: "left",
        marginTop: "30px",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingBottom: "30px",
      }}
    >
      <h2 className="title">
        <span className="vl" style={{ marginRight: "15px" }}></span>What I Do
      </h2>
      <p className="subTitle">
        I have 3 years of experience of building softwares for clients.Below is
        a quick overview of my main technical skill sets and technologies I use.
        Want to find out more about my experience? Check out my online resume
        and project portfolio.
      </p>
      <p className="subTitle2" style={{ textAlign: "center" }}>
      <FontAwesomeIcon
          data-tip
          data-for="figmatip"
          size="4x"
          icon={faFigma}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          data-tip
          data-for="javaTip"
          size="5x"
          icon={faJava}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          data-tip
          data-for="jsTip"
          size="5x"
          icon={faJsSquare}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          data-tip
          data-for="cssTip"
          size="5x"
          icon={faCss3Alt}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          size="5x"
          data-tip
          data-for="htmlTip"
          icon={faHtml5}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          data-tip
          data-for="androidTip"
          size="5x"
          icon={faAndroid}
          style={{ margin: "20px" }}
        />
      </p>
      <p className="subTitle2" style={{ textAlign: "center" }}>
        <FontAwesomeIcon
          data-tip
          data-for="angularTip"
          size="5x"
          icon={faAngular}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          size="5x"
          data-tip
          data-for="reactTip"
          icon={faReact}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          size="5x"
          data-tip
          data-for="gitTip"
          icon={faGit}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          size="5x"
          data-tip
          data-for="adobeTip"
          icon={faAdobe}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          size="5x"
          data-tip
          data-for="pyTip"
          icon={faPython}
          style={{ margin: "20px" }}
        />
      </p>
      <p className="subTitle2" style={{ textAlign: "center" }}>
        <FontAwesomeIcon
          size="5x"
          data-tip
          data-for="linuxTip"
          icon={faLinux}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          size="5x"
          data-tip
          data-for="nodeTip"
          icon={faNodeJs}
          style={{ margin: "20px" }}
        />
        <FontAwesomeIcon
          data-tip
          data-for="wordpressTip"
          size="5x"
          icon={faWordpress}
          style={{ margin: "20px" }}
        />
      </p>

      {/* Tips for images */}
      <ReactTooltip id="figmatip" place="top" effect="solid" type="light">
        Figma
      </ReactTooltip>
      <ReactTooltip id="javaTip" place="top" effect="solid" type="light">
        Java
      </ReactTooltip>
      <ReactTooltip id="jsTip" place="top" effect="solid" type="light">
        JavaScript
      </ReactTooltip>
      <ReactTooltip id="cssTip" place="top" effect="solid" type="light">
        CSS3
      </ReactTooltip>
      <ReactTooltip id="htmlTip" place="top" effect="solid" type="light">
        HTML5
      </ReactTooltip>
      <ReactTooltip id="androidTip" place="top" effect="solid" type="light">
        Android Development
      </ReactTooltip>
      <ReactTooltip id="angularTip" place="top" effect="solid" type="light">
        Angular.js
      </ReactTooltip>
      <ReactTooltip id="reactTip" place="top" effect="solid" type="light">
        React.js
      </ReactTooltip>
      <ReactTooltip id="gitTip" place="top" effect="solid" type="light">
        Git
      </ReactTooltip>
      <ReactTooltip id="adobeTip" place="top" effect="solid" type="light">
        Adobe Ps/Xd/Ai
      </ReactTooltip>
      <ReactTooltip id="pyTip" place="top" effect="solid" type="light">
        Python
      </ReactTooltip>
      <ReactTooltip id="linuxTip" place="top" effect="solid" type="light">
        Linux
      </ReactTooltip>
      <ReactTooltip id="nodeTip" place="top" effect="solid" type="light">
        Node.js
      </ReactTooltip>
      <ReactTooltip id="wordpressTip" place="top" effect="solid" type="light">
        Wordpress
      </ReactTooltip>
    </div>
  );
};

export default Skills;
