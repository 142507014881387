import React from "react";
import Sidebar from "../Components/Sidebar";
import MenuAM from "../Components/MenuAM";
import PortfolioMain from "../Components/PortfolioMain";
import Footer from "../Components/Footer";

const Portfolio = () => {
  return (
    <div className="wrapper">
      <Sidebar type="cmp2" />
      <div id="content">
        <MenuAM />
        <PortfolioMain style={{ padding: "10px" }} />
        <hr className="horizontal-line-css" />
        <Footer />
      </div>
    </div>
  );
};

export default Portfolio;
